import React, { useState, useEffect } from 'react';
import Pagination from 'react-js-pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import IconButton from '@material-ui/core/IconButton';
import { Link, useNavigate } from 'react-router-dom';
import { ResponsiveContainer } from 'recharts';

import { AdminAPI } from '../../../../apis';
import { showDate } from '../../../../utils/functions';
import { showToast } from '../../../../utils/toast';
import Panel from '../../../../shared/components/PanelWithExcel';
import Search from '../../../../shared/components/Search';
import Loader from '../../../../containers/Loader/index';
import { useLanguage } from '../../../../i18n/LanguageContext';

const DataTable = () => {
  const navigate = useNavigate();

  const { isRTL, translate } = useLanguage();

  const [state, setState] = useState({
    id: '',
    items: [],
    pageRange: 5,
    page: 1,
    perPage: 10,
    totalItemCount: 0,
    alertStatus: false,
    alertDelete: false,
    searchField: '',
    isLoading: false,
  });

  useEffect(() => {
    itemsListing(state.searchField, state.page);
  }, []);

  const itemsListing = async (searchField, page) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));

    const response = await AdminAPI.getAllAdmins(
      searchField,
      page,
      state.perPage
    );
    if (response.status === 200) {
      setState((prevState) => ({
        ...prevState,
        items: response.data.data.listing,
        totalItemCount: response.data.data.count,
        isLoading: false,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
      }));
      return response.data.message
        ? showToast(response.data.message, 'error')
        : showToast(translate('error'), 'error');
    }
  };

  const onActivateDeactivate = async (id) => {
    setState((prevState) => ({
      ...prevState,
      alertStatus: true,
      id: id,
    }));
  };

  const onDeleteHandeler = async (id) => {
    setState((prevState) => ({
      ...prevState,
      alertDelete: true,
      id: id,
    }));
  };

  const onConfirmStatus = async (val) => {
    if (val) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await AdminAPI.activateDeactivateAdmin(state.id);
      if (response.status === 200) {
        itemsListing(state.searchField, state.page);
        setState((prevState) => ({
          ...prevState,
          alertStatus: false,
          isLoading: false,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        alertStatus: false,
      }));
    }
  };

  const onConfirmDelete = async (val) => {
    if (val) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const response = await AdminAPI.deleteAdmin(state.id);
      if (response.status === 200) {
        itemsListing(state.searchField, state.page);
        setState((prevState) => ({
          ...prevState,
          alertDelete: false,
          isLoading: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          isLoading: false,
          alertDelete: false,
        }));
        return response.data.message
          ? showToast(response.data.message, 'error')
          : showToast(translate('error'), 'error');
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        alertDelete: false,
      }));
    }
  };

  const handlePageChange = (pageNumber) => {
    setState((prevState) => ({ ...prevState, page: pageNumber }));
    itemsListing(state.searchField, pageNumber);
  };

  const setSearchField = (field) => {
    setState((prevState) => ({
      ...prevState,
      searchField: field,
    }));
    itemsListing(field, state.page);
  };

  return (
    <div className="card">
      <div className="content">
        <Panel
          xl={12}
          lg={12}
          md={12}
          xs={12}
          title=""
          subhead=""
          refreshFunc={() => itemsListing(state.searchField, state.page)}
          addFunc={() => {
            navigate('/area/add');
          }}
          placement={isRTL ? 'rtl' : 'ltr'}
        >
          <Search
            placeholder={translate('searchByNameOrMobile')}
            searchField={state.searchField}
            setSearchField={setSearchField}
            search={() => itemsListing(state.searchField, state.page)}
          />
          <div dir={isRTL ? 'rtl' : 'ltr'}>
            {state.isLoading && <Loader />}
            <ResponsiveContainer className="dashboard__area">
              <table className="table table-striped ">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>{translate('fullName')}</th>
                    <th>{translate('email')}</th>
                    <th>{translate('isSuperAdmin')}</th>
                    <th>
                      <div className="table-search cursor-pointer">
                        {translate('registeredOn')}
                      </div>
                    </th>
                    <th>{translate('action')}</th>
                  </tr>
                </thead>
                <tbody>
                  {state.items.length > 0 &&
                    state.items.map((val, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {i +
                              (state.page * state.perPage - state.perPage) +
                              1}
                          </td>
                          <td>{isRTL ? val?.nameAr : val?.nameEn}</td>
                          <td>{val?.email}</td>
                          <td>
                            {val?.isSuperAdmin
                              ? translate('yes')
                              : translate('no')}
                          </td>
                          <td>{showDate(val.createdAt)}</td>
                          <td>
                            <Link
                              to={{
                                pathname: '/admins/view',
                                search: `?id=${val._id}`,
                              }}
                            >
                              <IconButton
                                className="text-primary"
                                aria-label="Edit"
                              >
                                <i title="Edit" className="icon-eye"></i>
                              </IconButton>
                            </Link>
                            <Link
                              to={{
                                pathname: '/admins/edit',
                                search: `?id=${val._id}`,
                              }}
                            >
                              <IconButton
                                className="text-primary"
                                aria-label="Edit"
                              >
                                <i title="Edit" className="icon-pencil"></i>
                              </IconButton>
                            </Link>
                            <IconButton
                              onClick={() => onActivateDeactivate(val._id)}
                              className={
                                val.status === 1 ? 'text-info' : 'text-danger'
                              }
                              aria-label="Edit"
                            >
                              <span
                                title={val.status === 2 ? 'Unblock' : 'Block'}
                                className="material-icons mr-10"
                              >
                                {val.status === 2 ? 'lock' : 'lock_open'}
                              </span>
                            </IconButton>
                            <IconButton
                              onClick={() => onDeleteHandeler(val._id)}
                              className="text-danger"
                              aria-label="Edit"
                            >
                              <span
                                className="glyphicon glyphicon-trash"
                                title="delete"
                              ></span>
                            </IconButton>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </ResponsiveContainer>
            <SweetAlert
              info
              showCancel
              custom
              confirmBtnText={translate('yes')}
              show={state.alertStatus}
              cancelBtnText={translate('no')}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              btnSize="sm"
              title={translate('changeStatusAlert')}
              onConfirm={() => onConfirmStatus(true)}
              onCancel={() => onConfirmStatus(false)}
            ></SweetAlert>
            <SweetAlert
              danger
              showCancel
              custom
              confirmBtnText={translate('yes')}
              show={state.alertDelete}
              cancelBtnText={translate('no')}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              btnSize="sm"
              title={translate('deleteAlert')}
              onConfirm={() => onConfirmDelete(true)}
              onCancel={() => onConfirmDelete(false)}
            ></SweetAlert>
            <div className="row">
              <div className="col-md-12">
                <Pagination
                  activePage={state.page}
                  itemsCountPerPage={state.perPage}
                  totalItemsCount={state.totalItemCount}
                  pageRangeDisplayed={state.pageRange}
                  onChange={(n) => handlePageChange(n)}
                />
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default DataTable;

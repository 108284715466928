import React, { useState } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

import { useLanguage } from '../../../../i18n/LanguageContext';
import { userTypesEn, userTypesAr } from '../../../../utils/allDropdownData';
import ImageUploader from '../../../../containers/ImageUploader';
import { UserAPI } from '../../../../apis';
import { showToast } from '../../../../utils/toast';
import { validatePhoneNumber } from '../../../../utils/functions';
import { sessionExpire } from '../../../../redux/actions/authAction';
import useErrorHandling from '../../../../utils/error';

const HorizontalForm = ({ handleSubmit, reset }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleError = useErrorHandling();

  const { isRTL, translate } = useLanguage();
  const [formData, setFormData] = useState({
    nameEn: '',
    nameAr: '',
    password: '',
    phoneNumber: '',
    userType: userTypesEn[0].value,
    image: '',
    license: '',
  });

  const _onSelectUserTypeHandeller = (e) => {
    setFormData({ ...formData, userType: e.value });
  };

  const handleUploadedMainImage = (imageName) => {
    setFormData({ ...formData, image: imageName[0] });
  };

  const handleUploadCompanyLicense = (licenseName) => {
    setFormData({ ...formData, license: licenseName[0] });
  };
  const _onAddUserHandeller = async () => {
    if (!validatePhoneNumber(formData.phoneNumber)) {
      return showToast(translate('invalidPhoneNumberAlert'), 'error');
    }

    const dataToSend = {
      nameAr: formData.nameAr,
      nameEn: formData.nameEn,
      password: formData.password,
      phoneNumber: formData.phoneNumber,
      userType: formData.userType,
      image: formData.image,
      license: formData.license,
    };

    try {
      const response = await UserAPI.addUser(dataToSend);
      if (response.status === 200) {
        showToast(translate('addedSuccessfully'), 'success');
        dataToSend.userType == 2
          ? navigate('/users/list')
          : navigate('/users/companyList');
      } else {
        showToast(response.message || translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('fullNameAr')}
                <span className="text-danger">*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="fullNameAr"
                  component="input"
                  type="text"
                  placeholder={translate('enterFullName')}
                  value={formData.firstNameAr}
                  onChange={(e) =>
                    setFormData({ ...formData, nameAr: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('fullNameEn')}
                <span className="text-danger">*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="fullNameEn"
                  component="input"
                  type="text"
                  placeholder={translate('enterFullName')}
                  value={formData.firstNameEn}
                  onChange={(e) =>
                    setFormData({ ...formData, nameEn: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('password')}
                <span className="text-danger">*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="password"
                  component="input"
                  type="text"
                  placeholder={translate('enterPassword')}
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('phoneNumber')}
                <span className="text-danger">*</span>
              </span>
              <div className="form__form-group-field">
                <Field
                  name="phoneNumber"
                  component="input"
                  type="text"
                  placeholder={translate('enterPhoneNumber')}
                  value={formData.phoneNumber}
                  onChange={(e) =>
                    setFormData({ ...formData, phoneNumber: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('userType')}
                <span className="text-danger">*</span>
              </span>
              <div className="form__form-group-field">
                <Select
                  name="userType"
                  className="react-select"
                  placeholder="Select User type"
                  defaultValue={isRTL ? userTypesAr[0] : userTypesEn[0]}
                  options={isRTL ? userTypesAr : userTypesEn}
                  onChange={(e) => _onSelectUserTypeHandeller(e)}
                />
              </div>
            </div>

            {formData.userType === 3 && (
              <>
                <h5 className="bold-text mb-4" style={{ marginTop: '50px' }}>
                  {translate('uploadCompanyLicense')}
                </h5>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <ImageUploader
                      onImageUpload={handleUploadCompanyLicense}
                      singleUpload={true}
                    />
                  </div>
                </div>
              </>
            )}

            <h5
              className="bold-text mb-4"
              style={{
                marginTop: '50px',
              }}
            >
              {translate('uploadUserProfileImage')}
            </h5>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <ImageUploader
                  onImageUpload={handleUploadedMainImage}
                  singleUpload={true}
                />
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={
                  !formData.nameEn ||
                  !formData.nameAr ||
                  !formData.password ||
                  !formData.phoneNumber ||
                  !formData.userType
                }
                onClick={_onAddUserHandeller}
              >
                {translate('submit')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(HorizontalForm);

/* eslint-disable import/no-anonymous-default-export */
import {
  SET_IS_LOGGED_USER,
  SET_LOGGED_USER_DATA,
  LOGOUT,
  SET_LOOKUP_DATA,
} from '../actions/ActionTypes';

const initialState = {
  isLoggedIn: false,
  loggedId: null,
  loggedName: '',
  loggedEmail: '',
  isAgent: false,
  token: '',
  lookups: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_IS_LOGGED_USER:
      return {
        ...state,
        isLoggedIn: action.isLoggedIn,
      };
    case SET_LOGGED_USER_DATA:
      return {
        ...state,
        isLoggedIn: true,
        loggedId: action?.data?._id,
        token: action?.data?.token,
        loggedName: action?.data?.name,
        isAgent: action?.data?.isAgent,
        // loggedEmail: action?.email,
      };
    case SET_LOOKUP_DATA:
      return {
        ...state,
        lookups: action?.data,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        loggedId: null,
        loggedName: '',
        loggedEmail: '',
        isAgent: false,
        token: '',
      };
    default:
      return state;
  }
}

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';

import { useLanguage } from '../../../../i18n/LanguageContext';
import { showToast } from '../../../../utils/toast';
import { AgentAPI } from '../../../../apis';

const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const HorizontalForm = () => {
  const { isRTL, translate } = useLanguage();
  const dispatch = useDispatch();

  const [formData, setFormData] = useState({
    userId: '',
    nameEn: '',
    nameAr: '',
    email: '',
    userType: '',
    createdAt: null,
  });

  useEffect(() => {
    getUserHandler();
  }, []);

  const getUserHandler = async () => {
    const url_string = document.URL;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    const response = await AgentAPI.getAgentById(id);
    if (response.status === 200) {
      setFormData({
        userId: response.data.data?._id,
        nameEn: response.data.data?.nameEn,
        nameAr: response.data.data?.nameAr,
        email: response.data.data?.email,
        userType: response.data.data?.userType,
        createdAt: response.data.data?.createdAt,
      });
    } else {
      return response.data.message
        ? showToast(response.data.message, 'error')
        : showToast(translate('error'), 'error');
    }
  };

  const showDate = (gotDate) => {
    let getDate = new Date(gotDate);
    return `${getDate.getDate()} ${
      month[getDate.getMonth()]
    } ${getDate.getFullYear()}`;
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal">
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('fullNameAr')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.nameAr}
                  disabled
                  name="firstnameAr"
                  component="input"
                  type="text"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('fullNameEn')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.nameEn}
                  disabled
                  name="firstnameEn"
                  component="input"
                  type="text"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('email')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.email}
                  disabled
                  name="email"
                  component="input"
                  type="text"
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('registeredOn')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={showDate(formData.createdAt)}
                  name="approvalDate"
                  component="input"
                  type="text"
                  placeholder="Approval Date"
                  disabled
                />
              </div>
            </div>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);

import React, { useEffect, useState } from 'react';
import { Button, ButtonToolbar, Card, CardBody, Col } from 'reactstrap';
import { reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

import { UPLOADS_URL } from '../../../../config';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { userTypesEn, userTypesAr } from '../../../../utils/allDropdownData';
import { showToast } from '../../../../utils/toast';
import ImageUploader from '../../../../containers/ImageUploader';
import { UserAPI, EstateAPI, ContractAPI } from '../../../../apis';
import { validatePhoneNumber } from '../../../../utils/functions';
import useErrorHandling from '../../../../utils/error';

const HorizontalForm = ({ handleSubmit }) => {
  const navigate = useNavigate();
  const handleError = useErrorHandling();
  const { isRTL, translate } = useLanguage();

  const url_string = document.URL;
  const url = new URL(url_string);
  const id = url.searchParams.get('id');

  const [formData, setFormData] = useState({
    nameEn: '',
    nameAr: '',
    phoneNumber: '',
    password: '',
    userType: '',
    image: '',
    license: '',
  });

  const [dataToSend, setDataToSend] = useState({
    userId: id,
  });

  const [userContract, setUserContract] = useState({
    title: '',
    fileName: '',
    estateId: '',
    userId: id,
  });

  const [userEstates, setUserEstates] = useState([]);

  const getUserById = async (id) => {
    try {
      const response = await UserAPI.getUserById(id, isRTL ? 'ar' : 'en');
      if (response.status === 200) {
        const { nameEn, nameAr, phoneNumber, userType, image } =
          response.data.data;
        setFormData({
          nameEn,
          nameAr,
          phoneNumber,
          userType,
          image,
        });
      } else {
        showToast(response.message || translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };

  const getUserEstates = async (id) => {
    try {
      const response = await EstateAPI.getUserEstates(id);
      if (response.status === 200) {
        setUserEstates(response.data.data);
      } else {
        showToast(response.message || translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    getUserById(id);
    getUserEstates(id);
  }, []);

  const _onSelectUserTypeHandeller = (e) => {
    setFormData({ ...formData, userType: e.value });
    setDataToSend({ ...dataToSend, userType: e.value });
  };

  const onSelectEstateHandler = (e) => {
    setUserContract({ ...userContract, estateId: e.value });
  };

  const handleUploadedProfileImage = (imageName) => {
    setFormData({ ...formData, image: imageName[0] });
    setDataToSend({ ...dataToSend, image: imageName[0] });
  };

  const handleAddNewUserContract = async () => {
    try {
      const response = ContractAPI.addNewUserContract(userContract);
      if (response.status === 200) {
        showToast(translate('contractUploadedSuccessfully'), 'success');
      } else {
        showToast(response.message || translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleUploadUserContract = (contractName) => {
    setUserContract({ ...userContract, fileName: contractName[0] });
  };

  const handleUploadCompanyLicense = (licenseName) => {
    setFormData({ ...formData, license: licenseName[0] });
    setDataToSend({ ...dataToSend, license: licenseName[0] });
  };

  const handleRemoveImage = () => {
    setFormData({ ...formData, image: '' });
    setDataToSend({ ...dataToSend, image: '' });
  };

  const _onEditUserHandeller = async () => {
    try {
      if (dataToSend.phoneNumber) {
        if (!validatePhoneNumber(dataToSend.phoneNumber)) {
          return showToast(translate('invalidPhoneNumberAlert'), 'error');
        }
      }
      if (userContract.fileName) {
        await handleAddNewUserContract();
      }
      const response = await UserAPI.editUser(dataToSend);
      if (response.status === 200) {
        getUserById(id);
        showToast(translate('updatedSuccessfully'), 'success');
        navigate('/users/list');
      } else {
        showToast(response.message || translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('fullNameAr')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.nameAr}
                  name="nameAr"
                  component="input"
                  type="text"
                  placeholder={translate('enterFullName')}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      nameAr: e.target.value,
                    });
                    setDataToSend({
                      ...dataToSend,
                      nameAr: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('fullNameEn')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.nameEn}
                  name="nameEn"
                  component="input"
                  type="text"
                  placeholder={translate('enterFullName')}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      nameEn: e.target.value,
                    });
                    setDataToSend({
                      ...dataToSend,
                      nameEn: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('phoneNumber')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.phoneNumber}
                  name="phoneNumber"
                  component="input"
                  type="text"
                  placeholder={translate('enterPhoneNumber')}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      phoneNumber: e.target.value,
                    });
                    setDataToSend({
                      ...dataToSend,
                      phoneNumber: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('password')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.password}
                  name="password"
                  component="input"
                  type="text"
                  placeholder={translate('enterPassword')}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      password: e.target.value,
                    });
                    setDataToSend({
                      ...dataToSend,
                      password: e.target.value,
                    });
                  }}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('userType')}
              </span>
              <div className="form__form-group-field">
                <Select
                  name="userType"
                  className="react-select"
                  placeholder="Select User type"
                  defaultValue={isRTL ? userTypesAr[0] : userTypesEn[0]}
                  options={isRTL ? userTypesAr : userTypesEn}
                  onChange={(e) => _onSelectUserTypeHandeller(e)}
                />
              </div>
            </div>

            <h5 className="bold-text mb-4" style={{ marginTop: '50px' }}>
              {translate('addNewContract')}
            </h5>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('title')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={userContract.title}
                  name={'title'}
                  component="input"
                  type="text"
                  placeholder={translate('enterTitle')}
                  onChange={(e) =>
                    setUserContract({
                      ...userContract,
                      title: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('estate')}
              </span>
              <div className="form__form-group-field">
                <Select
                  name="estate"
                  className="react-select"
                  placeholder={translate('selectEstate')}
                  options={userEstates?.map((estate) => ({
                    value: estate._id,
                    label: estate.referenceNo,
                  }))}
                  onChange={(e) => onSelectEstateHandler(e)}
                />
              </div>
            </div>

            <h5 className="bold-text mb-4" style={{ marginTop: '50px' }}>
              {translate('uploadUserContract')}
            </h5>
            <div className="form__form-group">
              <div className="form__form-group-field">
                <ImageUploader
                  onImageUpload={handleUploadUserContract}
                  singleUpload={true}
                />
              </div>
            </div>

            <h5 className="bold-text mb-4" style={{ marginTop: '50px' }}>
              {translate('uploadUserProfileImage')}
            </h5>

            <div className="form__form-group">
              <div className="form__form-group-field">
                <ImageUploader
                  onImageUpload={handleUploadedProfileImage}
                  singleUpload={true}
                />
              </div>
            </div>

            {formData.userType === 3 && (
              <>
                <h5 className="bold-text mb-4" style={{ marginTop: '50px' }}>
                  {translate('uploadCompanyLicense')}
                </h5>
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <ImageUploader
                      onImageUpload={handleUploadCompanyLicense}
                      singleUpload={true}
                    />
                  </div>
                </div>
              </>
            )}

            <h5
              className="bold-text mb-4"
              style={{
                marginTop: '50px',
              }}
            >
              {translate('userProfileImage')}
            </h5>

            <div
              style={{
                width: '100%',
              }}
            >
              {formData.image ? (
                <div
                  style={{
                    position: 'relative',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <img
                    src={`${UPLOADS_URL}uploads/${formData.image}`}
                    alt="profile"
                    style={{ maxWidth: '10%', height: 'auto' }}
                  />
                  <button
                    onClick={handleRemoveImage}
                    style={{
                      position: 'relative',
                      top: '-10px',
                      right: '-10px',
                      backgroundColor: 'red',
                      color: 'white',
                      border: 'none',
                      borderRadius: '50%',
                      width: '20px',
                      height: '20px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    &times;
                  </button>
                </div>
              ) : (
                `${translate('noProfileImage')}`
              )}
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={
                  !formData.nameEn ||
                  !formData.nameAr ||
                  !formData.phoneNumber ||
                  !formData.userType
                }
                onClick={_onEditUserHandeller}
              >
                {translate('submit')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);

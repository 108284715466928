import React from 'react';
import { FormGroup, Label, Input, Col, Row } from 'reactstrap';

const ResponsiveInput = ({
  label,
  value,
  onChange,
  type = 'text',
  id,
  placeholder,
  min,
}) => {
  if (type === 'checkbox') {
    return (
      <FormGroup>
        <Row className="align-items-center">
          <Col sm={4} md={3}>
            <Label className="mb-0 text-muted" style={{ fontSize: '15px' }}>
              {label}
            </Label>
          </Col>
          <Col sm={8} md={9}>
            <Input
              id={id}
              type="checkbox"
              checked={value}
              onChange={onChange}
              className="form-check-input"
              style={{ fontSize: '16px' }}
            />
          </Col>
        </Row>
      </FormGroup>
    );
  } else {
    return (
      <FormGroup>
        <Row className="align-items-center">
          <Col sm={4} md={3}>
            <Label
              for={id}
              className="mb-0 text-muted"
              style={{ fontSize: '15px' }}
            >
              {label}
            </Label>
          </Col>
          <Col sm={8} md={9}>
            <Input
              id={id}
              type={type}
              value={value}
              onChange={onChange}
              placeholder={placeholder}
              min={min}
              style={{ fontSize: '14px' }}
            />
          </Col>
        </Row>
      </FormGroup>
    );
  }
};

export default ResponsiveInput;

import React, { useEffect, useState } from 'react';
import { useLanguage } from '../../../i18n/LanguageContext';
import RequestItem from './components/index';
import { showToast } from '../../../utils/toast';
import { useDispatch } from 'react-redux';
import { sessionExpire } from '../../../redux/actions/authAction';
import Panel from '../../../shared/components/Panel';
import Search from '../../../shared/components/Search';
import Loader from '../../../containers/Loader/index';
import Pagination from 'react-js-pagination';
import { ResponsiveContainer } from 'recharts';
import { REQUEST_STATUS } from '../../../utils/allDropdownData';
import { EstateAPI } from '../../../apis';
import * as Config from '../../../config/index';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import useErrorHandling from '../../../utils/error';

const RentedPropertiesData = () => {
  const { isRTL, translate } = useLanguage();
  const dispatch = useDispatch();
  const handleError = useErrorHandling();

  const [state, setState] = useState({
    id: '',
    items: [],
    isLoading: false,
    totalItemCount: 0,
    pageRange: 5,
    page: 1,
    perPage: 10,
    alertReject: false,
    alertApprove: false,
    alertDelete: false,
    searchField: '',
  });

  useEffect(() => {
    getAllEstates(state.searchField, state.page);
  }, []);

  const getAllEstates = async (searchField, pageNumber) => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    const response = await EstateAPI.getAllEstates({
      status: REQUEST_STATUS.RENTED,
      searchField,
      pageNumber,
      perPage: state.perPage,
    });
    try {
      if (response.status === 200) {
        setState((prevState) => ({
          ...prevState,
          items: response.data.data.listing,
          totalItemCount: response.data.data.count,
          isLoading: false,
        }));
      } else {
        return response.data.message
          ? showToast(response.data.message, 'error')
          : showToast(translate('error'), 'error');
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, isLoading: false }));
      if (!error.response) {
        return showToast(String(error), 'error');
      } else if (error.response.status === 401) {
        showToast(translate('sessionExpire'), 'error');
        dispatch(sessionExpire());
      } else {
        return error.response.data.message
          ? showToast(error.response.data.message, 'error')
          : showToast(String(error), 'error');
      }
    }
  };

  const setSearchField = (field) => {
    setState((prevState) => ({
      ...prevState,
      searchField: field,
      page: 2,
    }));
    getAllEstates(field, 1);
  };

  const handlePageChange = (pageNumber) => {
    setState((prevState) => ({ ...prevState, page: pageNumber }));
    getAllEstates(state.searchField, pageNumber);
  };

  const onDeleteHandeler = async (id) => {
    setState((prevState) => ({
      ...prevState,
      alertDelete: true,
      id: id,
    }));
  };

  const onConfirmDelete = async (val) => {
    if (val) {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      const url = `${Config.BASE_URL}Admin-Estate/deleteEstate/${state.id}`;
      axios
        .patch(url)
        .then((response) => {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            alertDelete: false,
          }));
          if (response.status === 200) {
            getAllEstates(state.searchField, state.page);
          } else {
            return response.data.message
              ? showToast(response.data.message, 'error')
              : showToast(translate('error'), 'error');
          }
        })
        .catch((error) => {
          setState((prevState) => ({
            ...prevState,
            isLoading: false,
            alertDelete: false,
          }));
          handleError(error);
        });
    } else {
      setState((prevState) => ({
        ...prevState,
        alertDelete: false,
      }));
    }
  };

  return (
    <>
      <div className="card">
        <div className="content">
          <Panel
            xl={12}
            lg={12}
            md={12}
            xs={12}
            title=""
            subhead=""
            refreshFunc={() => getAllEstates(state.searchField, state.page)}
            withAddFunc={false}
            placement={isRTL ? 'rtl' : 'ltr'}
          >
            <Search
              placeholder={translate('requestSearch')}
              searchField={state.searchField}
              setSearchField={setSearchField}
              search={() => {
                getAllEstates(state.searchField, state.page);
              }}
            />
            <div dir={isRTL ? 'rtl' : 'ltr'}>
              {state.isLoading && <Loader />}
              <ResponsiveContainer className="dashboard__area">
                <div className="add-property-page--content">
                  <div
                    className="requests-header d-flex justify-content-between"
                    style={{ marginBottom: '20px' }}
                  >
                    <h1>{translate('properties')}</h1>
                  </div>
                  {state?.items?.map((request) => {
                    return (
                      <RequestItem
                        request={request}
                        onDelete={() => onDeleteHandeler(request?._id)}
                      />
                    );
                  })}
                </div>
              </ResponsiveContainer>
              <div className="row">
                <div className="col-md-12">
                  <Pagination
                    activePage={state.page}
                    itemsCountPerPage={state.perPage}
                    totalItemsCount={state.totalItemCount}
                    pageRangeDisplayed={state.pageRange}
                    onChange={(n) => handlePageChange(n)}
                  />
                </div>
              </div>
            </div>
          </Panel>
        </div>
        <SweetAlert
          success
          showCancel
          custom
          confirmBtnText={translate('yes')}
          show={state.alertDelete}
          cancelBtnText={translate('no')}
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="default"
          btnSize="sm"
          title={translate('deleteAlert')}
          onConfirm={() => onConfirmDelete(true)}
          onCancel={() => onConfirmDelete(false)}
        />
      </div>
    </>
  );
};

export default RentedPropertiesData;

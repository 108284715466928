import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import axios from 'axios';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import MapPicker from 'react-google-map-picker';
import { useDispatch, useSelector } from 'react-redux';
import 'react-dropzone-uploader/dist/styles.css';
import { Field, reduxForm } from 'redux-form';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';

import { booleansAr, booleansEn } from '../../../../utils/allDropdownData';
import * as Config from '../../../../config';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { showToast } from '../../../../utils/toast';
import { LookupsAPI, UserAPI } from '../../../../apis';
import useErrorHandling from '../../../../utils/error';
import ImageUploader from '../../../../containers/ImageUploader';
import StringArray from '../../../../shared/components/StringArray';
import { setLookupsData } from '../../../../redux/actions/authAction';
import {
  handleDescChange,
  handleInputChange,
  selectAddons,
  showDate,
} from '../../../../utils/functions';
import VideoPlayer from '../../../../containers/VideoPlayer';
import ImageCarousel from '../../../../containers/imageCarousel';

export const BOOLEANS = [
  { title: { en: 'Yes', ar: 'نعم' }, value: true },
  { title: { en: 'No', ar: 'لا' }, value: false },
];

const HorizontalForm = ({
  handleSubmit,
  actionId,
  status,
  propertyId,
  ACTIONS_IDS,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useErrorHandling();
  const { isRTL, translate, language } = useLanguage();

  const lookups = useSelector((state) => state.auth.lookups);
  const isAgent = useSelector((state) => state.auth.isAgent);

  const [zoom, setZoom] = useState(13);
  const [isFirstLaunch, setIsFirstLaunch] = useState(true);
  const [propertyTypes, setPropertyTypes] = useState([]);
  const [propertyCategories, setPropertyCategories] = useState([]);
  const [buyerRenterTypes, setBuyerRenterTypes] = useState([]);
  const [areas, setAreas] = useState([]);
  const [addons, setAddons] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [fieldsToDisplay, setFieldsToDisplay] = useState([]);

  let isRequest = status == 1 || status == 2 || status == 8;

  const locationByStreetAR = useMemo(
    () =>
      lookups?.locationByStreets?.map((item) => ({
        value: item._id,
        label: item.title.ar,
      })),
    [lookups?.locationByStreets]
  );

  const locationByStreetEN = useMemo(
    () =>
      lookups?.locationByStreets?.map((item) => ({
        value: item._id,
        label: item.title.en,
      })),
    [lookups?.locationByStreets]
  );

  const locationByLineAR = useMemo(
    () =>
      lookups?.locationByLines?.map((item) => ({
        value: item.id,
        label: item.title.ar,
      })),
    [lookups?.locationByLines]
  );

  const locationByLineEN = useMemo(
    () =>
      lookups?.locationByLines?.map((item) => ({
        value: item.id,
        label: item.title.en,
      })),
    [lookups?.locationByLines]
  );

  const nationalityAR = useMemo(
    () =>
      lookups?.nationalities?.map((item) => ({
        value: item.id,
        label: item.title.ar,
      })),
    [lookups?.nationalities]
  );

  const nationalityEN = useMemo(
    () =>
      lookups?.nationalities?.map((item) => ({
        value: item.id,
        label: item.title.en,
      })),
    [lookups?.nationalities]
  );

  const durationOfRentAR = useMemo(
    () =>
      lookups?.typeOfRents?.map((item) => ({
        value: item.id,
        label: item.title.ar,
      })),
    [lookups?.typeOfRents]
  );

  const durationOfRentEN = useMemo(
    () =>
      lookups?.typeOfRents?.map((item) => ({
        value: item.id,
        label: item.title.en,
      })),
    [lookups?.typeOfRents]
  );

  const requestPurposeAr = useMemo(
    () =>
      lookups?.requestTypes?.map((item) => ({
        value: item.id,
        label: item.title.ar,
      })),
    [lookups?.requestTypes]
  );

  const requestPurposeEn = useMemo(
    () =>
      lookups?.requestTypes?.map((item) => ({
        value: item.id,
        label: item.title.en,
      })),
    [lookups?.requestTypes]
  );

  const [state, setState] = useState({
    _id: null,
    requestStatusId: 4,
    requestTypeId: null,
    propertyTypeId: null,
    governorateId: null,
    areaId: null,
    block: null,
    price: null,
    areaSM: null,
    rooms: null,
    nmbOfApartments: null,
    yearlyPercentage: null,
    parkingsCount: null,
    income: null,
    viewSM: null,
    masters: null,
    addonIds: null,
    locationByUser: null,
    additionalInfoByUser: null,
    location: null,
    isPremium: null,
    propertyCategory: null,
    buyerOrRenterType: null,
    bouncingBack: null,
    constructionAge: null,
    googleLocation: {
      type: 'Point',
      coordinates: [47.4818, 29.3117],
    },
    mainImage: null,
    images: null,
    videos: null,
    youtubeVideos: [],
    userId: '',
    description: { en: null, ar: null },
    streetNo: null,
    homeNo: null,
    viewers: 0,
    adminPersonalNotes: null,
    deletedAt: null,
    deletedBy: null,
    rentedOrSoldPrice: null,
    rentedOrSoldPriceMS: null,
    rentedOrSoldDate: null,
    typeOfRent: null,
    nationality: null,
    stage: null,
    locationByLine: null,
    locationByStreet: null,
    additionalInfo: { en: null, ar: null },
    floor: null,
    monthlyIncome: null,
    createdAt: new Date().toISOString(),
    allowSharePropertyImages: true,
  });

  const handleUploadedMainImage = (imageName) => {
    setState((prevState) => ({
      ...prevState,
      mainImage: imageName[0],
    }));
  };

  const handleUploadedImages = (imageNames) => {
    setState((prevState) => ({
      ...prevState,
      images: imageNames,
    }));
  };

  // Fetch all necessary data on initial render
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        // Fetch companies
        if (actionId == ACTIONS_IDS.ADD) {
          const companiesResponse = await UserAPI.getCompanyUsers();
          if (companiesResponse?.data?.data?.listing) {
            const companiesList = companiesResponse.data.data.listing.map(
              (company) => ({
                label: language === 'en' ? company.nameEn : company.nameAr,
                value: company._id,
              })
            );
            setCompanies(companiesList);
          }
        }

        if (addons.length === 0) {
          const addonsList = lookups.addons.map((addon) => ({
            label: addon.title[language === 'en' ? 'en' : 'ar'],
            value: addon._id,
          }));
          setAddons(addonsList);
        }

        if (buyerRenterTypes.length === 0) {
          const types = lookups.buyerRenterTypes.map((type) => ({
            label: type.title[language === 'en' ? 'en' : 'ar'],
            value: type.id,
          }));
          setBuyerRenterTypes(types);
        }
      } catch (error) {
        handleError(error);
      }
    };
    if (isFirstLaunch) {
      setIsFirstLaunch(false);
      fetchInitialData();
    }
    if (
      addons.length > 0 &&
      state?._id === null &&
      actionId != ACTIONS_IDS.ADD &&
      propertyId &&
      propertyId !== 0
    ) {
      getEstate();
    }
  }, [dispatch, handleError, isFirstLaunch, addons, state]);

  // Handle changes to `requestTypeId` and filter property types accordingly
  useEffect(() => {
    if (lookups && state.requestTypeId) {
      const filteredPropertyTypes = lookups.propertyTypes
        .filter(
          (element) =>
            (state.requestTypeId === 1 && element.rentStatus === 1) ||
            (state.requestTypeId === 2 && element.sellStatus === 1)
        )
        .map((element) => ({
          label: element.title[language === 'en' ? 'en' : 'ar'],
          value: element._id,
          adminFilterKeys: element.adminFilterKeys,
        }));
      setPropertyTypes(filteredPropertyTypes);
    }
  }, [lookups, state.requestTypeId, language]);

  // Handle property type selection and update fields to display
  useEffect(() => {
    const selectedPropertyType = propertyTypes.find(
      (type) => type.value === state.propertyTypeId
    );
    if (selectedPropertyType) {
      setFieldsToDisplay(selectedPropertyType.adminFilterKeys);
    }
  }, [propertyTypes, state.propertyTypeId]);

  // Memoized governorate options based on lookups
  const governorateOptions = useMemo(() => {
    if (lookups) {
      let governoratesList = [];
      governoratesList = lookups.governorates.map((governorate) => ({
        label: governorate.title[language === 'en' ? 'en' : 'ar'],
        value: governorate._id,
      }));
      if (state.governorateId) {
        const areasList =
          lookups.governorates.find((gov) => gov._id === state.governorateId)
            ?.areas || [];

        const formattedAreas = areasList.map((area) => ({
          label: area.title[language === 'en' ? 'en' : 'ar'],
          value: area._id,
        }));

        setAreas(formattedAreas);
      }

      return governoratesList;
    }
    return [];
  }, [lookups, state.governorateId, language]);

  const propertyCategoriesOptions = useMemo(() => {
    const PROPERTY_TYPE_CATEGORY_MAP = {
      //Apartment
      '662e9f4e054ca5c54d1254c7': [2, 3, 100],
      //Land
      '662fbe4691de133e802eb9b2': [1, 2, 3, 4, 5, 6, 100],
      //House
      '663fa93a9c4efd8426ab8177': [1, 2, 100],
      //Chalet
      '6642433aba2a912f77d85134': [1, 2, 4, 100],
      //Building
      '664244ec4ed766fa32aa33bb': [2, 3, 4, 100],
      //Mall
      '664246ccba2a912f77d85135': [2, 3, 4, 100],
      //Floor
      '66d5ea56f9fb927d9e0acf09': [2, 3, 100],
      //Duplex
      '66d5ea95f9fb927d9e0acf0a': [2, 3, 100],
    };
    const categoryIds = PROPERTY_TYPE_CATEGORY_MAP[state?.propertyTypeId] || [
      1, 2, 3, 4, 5, 6, 100,
    ];
    const filteredCategories = lookups?.propertyCategories.filter((obj) =>
      categoryIds.includes(obj.id)
    );
    return [
      ...filteredCategories.map((obj) => ({
        value: obj.id,
        label: language === 'en' ? obj.title.en : obj.title.ar,
      })),
    ];
  }, [lookups?.propertyCategories, language, state?.propertyTypeId]);

  const onChangeGovernorate = (e) => {
    setState({ ...state, governorateId: e.value });
    const secondList = [];
    lookups?.governorates?.forEach((element) => {
      if (element?._id === e.value) {
        element?.areas?.forEach((item) => {
          secondList.push({
            label: item?.title?.[language === 'en' ? 'en' : 'ar'],
            value: item?._id,
          });
        });
        setAreas(secondList);
      }
    });
  };

  const addEstate = async () => {
    let body = {
      ...state,
    };

    // if (!(await validate(body?.requestTypeId, 'requestPurpose'))) {
    //   return false;
    // }
    // if (!(await validate(body?.propertyTypeId, 'propertyType'))) {
    //   return false;
    // }
    // if (!(await validate(body?.governorateId, 'governorate'))) {
    //   return false;
    // }
    // if (!(await validate(body?.areaId, 'area'))) {
    //   return false;
    // }
    // if (!(await validate(body?.price, 'price'))) {
    //   return false;
    // }
    // if (!(await validate(body?.isPremium, 'isPremium'))) {
    //   return false;
    // }
    // if (!(await validate(body?.propertyCategory, 'propertyCategory'))) {
    //   return false;
    // }
    // if (!(await validate(body?.buyerOrRenterType, 'buyerOrRenterType'))) {
    //   return false;
    // }
    // if (!(await validate(body?.description?.en, 'descriptionEn'))) {
    //   return false;
    // }
    // if (!(await validate(body?.description?.ar, 'descriptionAr'))) {
    //   return false;
    // }
    // if (!(await validate(body?.mainImage, 'uploadPropertyMainImage'))) {
    //   return false;
    // }
    // if (!(await validate(body?.userId, 'requestUser'))) {
    //   return false;
    // }

    axios
      .post(`${Config.BASE_URL}Admin-Estate/addEstate`, body)
      .then((response) => {
        if (response.status === 200) {
          showToast(translate('success'), 'success');
          navigate('/properties');
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const activateEstate = async () => {
    let body = {
      ...state,
    };
    delete body['userDetails'];
    body.requestStatusId = 4;

    axios
      .put(`${Config.BASE_URL}Admin-Estate/activateRequest`, body)
      .then((response) => {
        if (response.status === 200) {
          showToast(translate('success'), 'success');
          navigate('/properties');
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const editEstate = async () => {
    let addons = [];
    if (state?.addonIds?.length > 0) {
      state.addonIds.forEach((element) => {
        addons.push(element.value);
      });
    }
    let body = {
      ...state,
      addonIds: addons,
    };

    axios
      .put(`${Config.BASE_URL}Admin-Estate/updateEstate`, body)
      .then((response) => {
        if (response.status === 200) {
          showToast(translate('success'), 'success');
          isAgent ? navigate('/visitingRequests') : navigate('/properties');
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getEstate = async () => {
    axios
      .get(
        `${Config.BASE_URL}Admin-Estate/getEstateByIdForActivation/${propertyId}`
      )
      .then((response) => {
        if (response.status === 200) {
          if (
            response.data.data?.addonIds &&
            response.data.data?.addonIds.length > 0 &&
            addons &&
            addons.length > 0
          ) {
            const addonIds = response.data.data.addonIds;
            let newArray = [];

            addonIds.forEach((element) => {
              addons.forEach((item) => {
                if (element === item.value) newArray.push(item);
              });
            });
            setState({ ...response.data.data, addonIds: newArray });
          } else {
            setState(response.data.data);
          }
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleRemoveMainImage = () => {
    setState({ ...state, mainImage: '' });
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = state.images.filter(
      (_, index) => index !== indexToRemove
    );
    setState({ ...state, images: updatedImages });
  };

  const handleLatChange = (e) => {
    const newLat = parseFloat(e.target.value) || 0;
    setState((prev) => ({
      ...prev,
      googleLocation: {
        ...prev.googleLocation,
        coordinates: [prev.googleLocation.coordinates[0], newLat],
      },
    }));
  };

  const handleLngChange = (e) => {
    const newLng = parseFloat(e.target.value) || 0;
    setState((prev) => ({
      ...prev,
      googleLocation: {
        ...prev.googleLocation,
        coordinates: [newLng, prev.googleLocation.coordinates[1]],
      },
    }));
  };

  const handleChangeLocation = (lat, lng) => {
    setState((prev) => ({
      ...prev,
      googleLocation: {
        ...prev.googleLocation,
        coordinates: [lng, lat], // [lng, lat] format
      },
    }));
  };

  // const handleChangeLocation = (lat, lng) => {
  //   const googleLocation = { type: 'Point', coordinates: [lng, lat] };
  //   setState((prevState) => ({
  //     ...prevState,
  //     googleLocation,
  //   }));
  // };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            {isRequest ? null : (
              <>
                <h4 className="text-justify row">
                  {translate('adminPrivatePropertyDetails')}
                </h4>
                <div className="card mt-4 mb-4 bg-light p-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('adminPersonalNotes')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={state?.adminPersonalNotes}
                        onChange={(e) => handleInputChange(e, setState)}
                        name="adminPersonalNotes"
                        component="input"
                        type="text"
                        placeholder=""
                        className="border"
                        disabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            {(state?.rentedOrSoldPrice !== null ||
              state?.rentedOrSoldPriceMS !== null) && (
              <>
                <h4 className="text-justify row">
                  {translate('sellRentPropertyDetails')}
                </h4>

                <div className="card mt-4 mb-4">
                  {state?.rentedOrSoldPrice && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('rentedOrSoldPrice')}
                      </span>
                      <div className="form__form-group-field">
                        <input value={state?.rentedOrSoldPrice} disabled />
                      </div>
                    </div>
                  )}

                  {state?.rentedOrSoldPriceMS && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('rentedOrSoldPriceMS')}
                      </span>
                      <div className="form__form-group-field">
                        <input value={state?.rentedOrSoldPriceMS} disabled />
                      </div>
                    </div>
                  )}

                  {fieldsToDisplay.includes('rentedOrSoldDate') &&
                    state?.rentedOrSoldDate && (
                      <div className="form__form-group">
                        <span className="form__form-group-label">
                          {translate('rentedOrSoldDate')}
                        </span>
                        <div className="form__form-group-field">
                          <input
                            value={showDate(state?.rentedOrSoldDate)}
                            disabled
                          />
                        </div>
                      </div>
                    )}
                </div>
              </>
            )}
            <h4 className="text-justify row">{translate('requestDetails')}</h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              {state?.referenceNo && (
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {translate('referenceNo')}
                  </span>
                  <div className="form__form-group-field">
                    <input
                      value={state?.referenceNo}
                      name="referenceNo"
                      component="input"
                      type="text"
                      sDisabled={true}
                    />
                  </div>
                </div>
              )}

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('requestPurpose')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="propertyTypeDetails"
                    className="react-select"
                    placeholder={''}
                    options={isRTL ? requestPurposeAr : requestPurposeEn}
                    value={
                      isRTL
                        ? requestPurposeAr.find(
                            (item) => item.value === state.requestTypeId
                          )
                        : requestPurposeEn.find(
                            (item) => item.value === state.requestTypeId
                          )
                    }
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        requestTypeId: e.value,
                      }))
                    }
                    isDisabled={actionId == ACTIONS_IDS.VIEW}
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('propertyType')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="propertyTypeId"
                    className="react-select"
                    placeholder={''}
                    options={propertyTypes}
                    value={propertyTypes.find(
                      (item) => item.value === state.propertyTypeId
                    )}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        propertyTypeId: e.value,
                      }))
                    }
                    isDisabled={actionId == ACTIONS_IDS.VIEW}
                  />
                </div>
              </div>

              {isRequest
                ? null
                : fieldsToDisplay.includes('propertyCategory') && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('propertyCategory')}
                      </span>
                      <div className="form__form-group-field">
                        <Select
                          name="propertyCategory"
                          className="react-select"
                          placeholder={''}
                          options={propertyCategoriesOptions}
                          value={propertyCategoriesOptions.find(
                            (item) => item.value === state.propertyCategory
                          )}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              propertyCategory: e.value,
                            }))
                          }
                          isDisabled={actionId == ACTIONS_IDS.VIEW}
                        />
                      </div>
                    </div>
                  )}

              {isRequest
                ? null
                : fieldsToDisplay.includes('buyerOrRenterType') && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('buyerOrRenterType')}
                      </span>
                      <div className="form__form-group-field">
                        <Select
                          name="propertyCategory"
                          className="react-select"
                          placeholder={''}
                          options={buyerRenterTypes}
                          value={buyerRenterTypes.find(
                            (item) => item.value === state.buyerOrRenterType
                          )}
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              buyerOrRenterType: e.value,
                            }))
                          }
                          isDisabled={actionId == ACTIONS_IDS.VIEW}
                        />
                      </div>
                    </div>
                  )}
              {!isRequest &&
                state.requestTypeId === 1 &&
                fieldsToDisplay.includes('typeOfRent') && (
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('durationOfRent')}
                    </span>
                    <div className="form__form-group-field">
                      <Select
                        name="propertyTypeDetails"
                        className="react-select"
                        placeholder={''}
                        options={isRTL ? durationOfRentAR : durationOfRentEN}
                        value={
                          isRTL
                            ? durationOfRentAR.find(
                                (item) => item.value === state.typeOfRent
                              )
                            : durationOfRentEN.find(
                                (item) => item.value === state.typeOfRent
                              )
                        }
                        onChange={(e) =>
                          setState((prevState) => ({
                            ...prevState,
                            typeOfRent: e.value,
                          }))
                        }
                        isDisabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                  </div>
                )}

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('requestDate')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={
                      state?.createdAt ? state.createdAt?.split('T')[0] : ''
                    }
                    onChange={(e) =>
                      setState({
                        ...state,
                        createdAt: `${e.target.value}T00:00:00.000Z`,
                      })
                    }
                    name="createdAt"
                    component="input"
                    type="date"
                    placeholder={translate('requestDate')}
                    sDisabled={actionId == ACTIONS_IDS.VIEW}
                  />
                </div>
              </div>
            </div>

            <h4 className="text-justify row">{translate('requestUser')}</h4>

            {actionId == ACTIONS_IDS.ADD && (
              <div className="card mt-4 mb-4 bg-light p-4">
                <div className="form__form-group">
                  <div className="form__form-group-field">
                    <Select
                      name="user"
                      menuPortalTarget={document.querySelector('body')}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      options={companies}
                      onChange={(e) => setState({ ...state, userId: e.value })}
                      isDisabled={actionId == ACTIONS_IDS.VIEW}
                    />
                  </div>
                </div>
              </div>
            )}

            {(actionId == ACTIONS_IDS.EDIT || actionId == ACTIONS_IDS.VIEW) && (
              <>
                <div className="card mt-4 mb-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('fullName')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={
                          language === 'en'
                            ? state?.userDetails?.nameEn
                            : state?.userDetails?.nameAr
                        }
                        disabled
                        name="firstname"
                        component="input"
                        type="text"
                        placeholder={translate('enterFullName')}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('phoneNumber')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={state?.userDetails?.phoneNumber}
                        disabled
                        name="phoneNumber"
                        component="input"
                        type="text"
                        placeholder="Enter Phone Number"
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('userType')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={
                          state.userDetails?.userType == 1
                            ? isRTL
                              ? 'شخصي'
                              : 'Personal'
                            : isRTL
                            ? 'شركة'
                            : 'Company'
                        }
                        disabled
                        name="userType"
                        component="input"
                        type="text"
                        placeholder="Enter User Type"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}

            <h4 className="text-justify row">
              {translate('locationPropertyDetails')}
            </h4>
            <div className="card mt-4 mb-4 bg-light p-4">
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('governorate')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="governorateId"
                    className="react-select"
                    placeholder={''}
                    options={governorateOptions}
                    value={governorateOptions.find(
                      (item) => item.value === state.governorateId
                    )}
                    onChange={(e) => onChangeGovernorate(e)}
                    isDisabled={actionId == ACTIONS_IDS.VIEW}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('area')}
                </span>
                <div className="form__form-group-field">
                  <Select
                    name="areaId"
                    className="react-select"
                    placeholder={''}
                    options={areas}
                    value={areas.find((item) => item.value === state.areaId)}
                    onChange={(e) =>
                      setState((prevState) => ({
                        ...prevState,
                        areaId: e.value,
                      }))
                    }
                    isDisabled={actionId == ACTIONS_IDS.VIEW}
                  />
                </div>
              </div>

              {/* <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('block')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.block < 0 ? '' : state?.block}
                    onChange={(e) => handleInputChange(e, setState)}
                    name="block"
                    component="input"
                    type="number"
                    placeholder=""
                    disabled={actionId == ACTIONS_IDS.VIEW}
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('street')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.streetNo}
                    onChange={(e) => handleInputChange(e, setState)}
                    name="streetNo"
                    component="input"
                    type="number"
                    placeholder=""
                    disabled={actionId == ACTIONS_IDS.VIEW}
                  />
                </div>
              </div>

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('homeNumber')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.homeNo}
                    onChange={(e) => handleInputChange(e, setState)}
                    name="homeNo"
                    component="input"
                    type="number"
                    placeholder=""
                    disabled={actionId == ACTIONS_IDS.VIEW}
                  />
                </div>
              </div> */}

              {fieldsToDisplay.includes('floor') && (
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {translate('floor')}
                  </span>
                  <div className="form__form-group-field">
                    <input
                      value={state?.floor}
                      onChange={(e) => handleInputChange(e, setState)}
                      name="floor"
                      component="input"
                      type="number"
                      placeholder=""
                      disabled={actionId == ACTIONS_IDS.VIEW}
                    />
                  </div>
                </div>
              )}

              {fieldsToDisplay.includes('location') && (
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {translate('location')}
                  </span>
                  <div className="form__form-group-field">
                    <input
                      value={state?.location}
                      onChange={(e) => handleInputChange(e, setState)}
                      name="location"
                      component="input"
                      type="text"
                      placeholder=""
                      disabled={actionId == ACTIONS_IDS.VIEW}
                    />
                  </div>
                </div>
              )}

              {state?.priceText || state?.areaText ? (
                <p style={{ marginBottom: 20 }}>{translate('byUser')}</p>
              ) : null}

              {state?.priceText && (
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {translate('price')}
                  </span>
                  <div className="form__form-group-field">
                    <input
                      value={state?.priceText}
                      // onChange={(e) => handleInputChange(e, setState)}
                      name="priceText"
                      component="input"
                      type="text"
                      placeholder=""
                      disabled={actionId == ACTIONS_IDS.VIEW}
                    />
                  </div>
                </div>
              )}

              {state?.areaText && (
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {translate('space')}
                  </span>
                  <div className="form__form-group-field">
                    <input
                      value={state?.areaText}
                      // onChange={(e) => handleInputChange(e, setState)}
                      name="areaText"
                      component="input"
                      type="text"
                      placeholder=""
                      disabled={actionId == ACTIONS_IDS.VIEW}
                    />
                  </div>
                </div>
              )}

              {fieldsToDisplay.includes('locationByUser') && (
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {translate('locationByUser')}
                  </span>
                  <div className="form__form-group-field">
                    <input
                      value={state?.locationByUser}
                      onChange={(e) => handleInputChange(e, setState)}
                      name="locationByUser"
                      component="input"
                      type="text"
                      placeholder=""
                      disabled={actionId == ACTIONS_IDS.VIEW}
                    />
                  </div>
                </div>
              )}

              <div className="form__form-group">
                <span className="form__form-group-label">
                  {translate('additionalInfoByUser')}
                </span>
                <div className="form__form-group-field">
                  <input
                    value={state?.additionalInfoByUser}
                    onChange={(e) => handleInputChange(e, setState)}
                    name="additionalInfoByUser"
                    component="input"
                    type="text"
                    placeholder=""
                    disabled={actionId == ACTIONS_IDS.VIEW}
                  />
                </div>
              </div>

              {(state?.allowSharePropertyImages === false ||
                state?.allowSharePropertyImages === true) && (
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {translate('propertyImagesShare')}
                  </span>
                  <div className="form__form-group-field">
                    <Field
                      name="radio_button"
                      component={renderRadioButtonField}
                      label={translate('AllowSharePropertyImages')}
                      radioValue={true}
                      value={state.allowSharePropertyImages}
                      defaultChecked={state.allowSharePropertyImages}
                      onChange={(e) =>
                        setState({ ...state, allowSharePropertyImages: true })
                      }
                    />
                    <Field
                      name="radio_button"
                      component={renderRadioButtonField}
                      label={translate('PreventSharePropertyImages')}
                      radioValue={false}
                      defaultChecked={state.allowSharePropertyImages}
                      value={state.allowSharePropertyImages}
                      onChange={(e) => {
                        setState({ ...state, allowSharePropertyImages: false });
                      }}
                    />
                  </div>
                </div>
              )}

              <>
                {/* <div className="form__form-group">
                  <span className="form__form-group-label">
                    {translate('longitude')}
                  </span>
                  <div className="form__form-group-field">
                    <input
                      value={state?.googleLocation?.coordinates?.[1]}
                      disabled
                    />
                  </div>
                </div>
                <div className="form__form-group">
                  <span className="form__form-group-label">
                    {translate('latitude')}
                  </span>
                  <div className="form__form-group-field">
                    <input
                      value={state?.googleLocation?.coordinates?.[0]}
                      disabled
                    />
                  </div>
                </div> */}
                <div
                  style={{
                    display: 'flex',
                    gap: '1rem',
                  }}
                >
                  {/* Left side: inputs for lat & lng */}
                  <div>
                    <div>
                      <label className="form__form-group-label">
                        {translate('longitude')}
                      </label>
                      <input
                        type="number"
                        step="0.000001"
                        value={state.googleLocation.coordinates[0]}
                        onChange={handleLngChange}
                        disabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                    <div>
                      <label className="form__form-group-label">
                        {translate('latitude')}
                      </label>
                      <input
                        type="number"
                        step="0.000001"
                        value={state.googleLocation.coordinates[1]}
                        onChange={handleLatChange}
                        disabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                  </div>

                  {/* Right side: the MapPicker component */}
                  <MapPicker
                    // Use 'location' prop for controlled mode
                    location={{
                      lat: state.googleLocation.coordinates[1] || 29.3117,
                      lng: state.googleLocation.coordinates[0] || 47.4818,
                    }}
                    zoom={zoom}
                    mapTypeId="roadmap"
                    style={{ height: '400px', width: '400px' }}
                    onChangeLocation={handleChangeLocation}
                    onChangeZoom={setZoom}
                    apiKey="AIzaSyAw90ySnVcKpKWvYQKB7EgIUMo_8hPpTZ4"
                  />
                </div>
                {/* <MapPicker
                  defaultLocation={{
                    lat: state?.googleLocation?.coordinates?.[1] || 29.3117,
                    lng: state?.googleLocation?.coordinates?.[0] || 47.4818,
                  }}
                  zoom={zoom}
                  mapTypeId="roadmap"
                  style={{ height: '400px', width: '400px' }}
                  onChangeLocation={
                    actionId == ACTIONS_IDS.VIEW
                      ? () => {}
                      : handleChangeLocation
                  }
                  onChangeZoom={setZoom}
                  apiKey="AIzaSyAw90ySnVcKpKWvYQKB7EgIUMo_8hPpTZ4"
                /> */}
              </>
            </div>
            {!isRequest && (
              <>
                <h4 className="text-justify row">
                  {translate('generalPropertyCharacteristics')}
                </h4>
                <div className="card mt-4 mb-4 bg-light p-4">
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('descriptionAr')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={state?.description?.ar}
                        onChange={(e) => handleDescChange(e, setState)}
                        name="description_ar"
                        component="input"
                        type="text"
                        placeholder=""
                        disabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('descriptionEn')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={state?.description?.en}
                        onChange={(e) => handleDescChange(e, setState)}
                        name="description_en"
                        component="input"
                        type="text"
                        placeholder=""
                        disabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('additionalInfoAr')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={state?.additionalInfo?.ar}
                        onChange={(e) => handleDescChange(e, setState)}
                        name="additionalInfo_ar"
                        component="input"
                        type="text"
                        placeholder=""
                        disabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('additionalInfoEn')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={state?.additionalInfo?.en}
                        onChange={(e) => handleDescChange(e, setState)}
                        name="additionalInfo_en"
                        component="input"
                        type="text"
                        placeholder=""
                        disabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                  </div>

                  {fieldsToDisplay.includes('stage') && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('stage')}
                      </span>
                      <div className="form__form-group-field">
                        <input
                          value={state?.stage}
                          onChange={(e) => handleInputChange(e, setState)}
                          name="stage"
                          component="input"
                          type="number"
                          placeholder=""
                          className="border"
                          min="1"
                          max="9"
                          disabled={actionId == ACTIONS_IDS.VIEW}
                        />
                      </div>
                    </div>
                  )}

                  {fieldsToDisplay.includes('locationByLine') && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('locationByLine')}
                      </span>
                      <div className="form__form-group-field">
                        <Select
                          name="propertyTypeDetails"
                          className="react-select"
                          placeholder={''}
                          options={isRTL ? locationByLineAR : locationByLineEN}
                          value={
                            isRTL
                              ? locationByLineAR.find(
                                  (item) => item.value === state.locationByLine
                                )
                              : locationByLineEN.find(
                                  (item) => item.value === state.locationByLine
                                )
                          }
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              locationByLine: e.value,
                            }))
                          }
                          isDisabled={actionId == ACTIONS_IDS.VIEW}
                        />
                      </div>
                    </div>
                  )}

                  {fieldsToDisplay.includes('locationByStreet') && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('locationByStreet')}
                      </span>
                      <div className="form__form-group-field">
                        <Select
                          name="propertyTypeDetails"
                          className="react-select"
                          placeholder={''}
                          options={
                            isRTL ? locationByStreetAR : locationByStreetEN
                          }
                          value={
                            isRTL
                              ? locationByStreetAR.find(
                                  (item) =>
                                    item.value === state.locationByStreet
                                )
                              : locationByStreetEN.find(
                                  (item) =>
                                    item.value === state.locationByStreet
                                )
                          }
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              locationByStreet: e.value,
                            }))
                          }
                          isDisabled={actionId == ACTIONS_IDS.VIEW}
                        />
                      </div>
                    </div>
                  )}

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('price')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={state?.price}
                        onChange={(e) => handleInputChange(e, setState)}
                        name="price"
                        component="input"
                        type="number"
                        placeholder=""
                        disabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                  </div>

                  {fieldsToDisplay.includes('monthlyIncome') && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('monthlyIncome')}
                      </span>
                      <div className="form__form-group-field">
                        <input
                          value={state?.monthlyIncome}
                          onChange={(e) => handleInputChange(e, setState)}
                          name="monthlyIncome"
                          component="input"
                          type="text"
                          placeholder=""
                          disabled={actionId == ACTIONS_IDS.VIEW}
                        />
                      </div>
                    </div>
                  )}

                  {fieldsToDisplay.includes('income') && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('income')}
                      </span>
                      <div className="form__form-group-field">
                        <input
                          value={state?.income}
                          onChange={(e) => handleInputChange(e, setState)}
                          name="income"
                          component="input"
                          type="number"
                          placeholder=""
                          disabled={actionId == ACTIONS_IDS.VIEW}
                        />
                      </div>
                    </div>
                  )}

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('areaSM')}
                    </span>
                    <div className="form__form-group-field">
                      <input
                        value={state?.areaSM}
                        onChange={(e) => handleInputChange(e, setState)}
                        name="areaSM"
                        component="input"
                        type="number"
                        placeholder=""
                        disabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                  </div>

                  {fieldsToDisplay.includes('viewSM') && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('viewSM')}
                      </span>
                      <div className="form__form-group-field">
                        <input
                          value={state?.viewSM}
                          onChange={(e) => handleInputChange(e, setState)}
                          name="viewSM"
                          component="input"
                          type="number"
                          placeholder=""
                          disabled={actionId == ACTIONS_IDS.VIEW}
                        />
                      </div>
                    </div>
                  )}

                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      {translate('isPremium')}
                    </span>
                    <div className="form__form-group-field">
                      <Select
                        name="isPremium"
                        className="react-select"
                        placeholder={''}
                        options={isRTL ? booleansAr : booleansEn}
                        value={
                          isRTL
                            ? booleansAr.find(
                                (item) => item.value === state.isPremium
                              )
                            : booleansEn.find(
                                (item) => item.value === state.isPremium
                              )
                        }
                        onChange={(e) =>
                          setState((prevState) => ({
                            ...prevState,
                            isPremium: e.value,
                          }))
                        }
                        isDisabled={actionId == ACTIONS_IDS.VIEW}
                      />
                    </div>
                  </div>

                  {fieldsToDisplay.includes('nationality') && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('nationality')}
                      </span>
                      <div className="form__form-group-field">
                        <Select
                          name="propertyTypeDetails"
                          className="react-select"
                          placeholder={''}
                          options={isRTL ? nationalityAR : nationalityEN}
                          value={
                            isRTL
                              ? nationalityAR.find(
                                  (item) => item.value === state.nationality
                                )
                              : nationalityEN.find(
                                  (item) => item.value === state.nationality
                                )
                          }
                          onChange={(e) =>
                            setState((prevState) => ({
                              ...prevState,
                              nationality: e.value,
                            }))
                          }
                        />
                      </div>
                    </div>
                  )}
                  {fieldsToDisplay.includes('yearlyPercentage') && (
                    <div className="form__form-group">
                      <span className="form__form-group-label">
                        {translate('yearlyPercentage')}
                      </span>
                      <div className="form__form-group-field">
                        <input
                          value={state?.yearlyPercentage}
                          onChange={(e) => handleInputChange(e, setState)}
                          name="yearlyPercentage"
                          component="input"
                          type="text"
                          placeholder=""
                          disabled={actionId == ACTIONS_IDS.VIEW}
                        />
                      </div>
                    </div>
                  )}
                </div>

                {(fieldsToDisplay.includes('rooms') ||
                  fieldsToDisplay.includes('parkingsCount') ||
                  fieldsToDisplay.includes('x')) &&
                  fieldsToDisplay.includes('masters') && (
                    <>
                      <h4 className="text-justify row">
                        {translate('internalPropertyCharacteristics')}
                      </h4>
                      <div className="card mt-4 mb-4 bg-light p-4">
                        {fieldsToDisplay.includes('rooms') && (
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {translate('rooms')}
                            </span>
                            <div className="form__form-group-field">
                              <input
                                value={state?.rooms}
                                onChange={(e) => handleInputChange(e, setState)}
                                name="rooms"
                                component="input"
                                type="number"
                                placeholder=""
                                disabled={actionId == ACTIONS_IDS.VIEW}
                              />
                            </div>
                          </div>
                        )}

                        {fieldsToDisplay.includes('nmbOfApartments') && (
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {translate('nmbOfApartments')}
                            </span>
                            <div className="form__form-group-field">
                              <input
                                value={state?.nmbOfApartments}
                                onChange={(e) => handleInputChange(e, setState)}
                                name="nmbOfApartments"
                                component="input"
                                type="number"
                                placeholder=""
                                disabled={actionId == ACTIONS_IDS.VIEW}
                              />
                            </div>
                          </div>
                        )}
                        {fieldsToDisplay.includes('parkingsCount') && (
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {translate('parkingsCount')}
                            </span>
                            <div className="form__form-group-field">
                              <input
                                value={state?.parkingsCount}
                                onChange={(e) => handleInputChange(e, setState)}
                                name="parkingsCount"
                                component="input"
                                type="number"
                                placeholder=""
                                disabled={actionId == ACTIONS_IDS.VIEW}
                              />
                            </div>
                          </div>
                        )}
                        {fieldsToDisplay.includes('masters') && (
                          <div className="form__form-group">
                            <span className="form__form-group-label">
                              {translate('masters')}
                            </span>
                            <div className="form__form-group-field">
                              <input
                                value={state?.masters}
                                onChange={(e) => handleInputChange(e, setState)}
                                name="masters"
                                component="input"
                                type="number"
                                placeholder=""
                                disabled={actionId == ACTIONS_IDS.VIEW}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </>
                  )}

                {fieldsToDisplay.includes('bouncingBack') && (
                  <>
                    <h4 className="text-justify row">
                      {translate('externalPropertyCharacteristics')}
                    </h4>
                    <div className="card mt-4 mb-4 bg-light p-4">
                      {fieldsToDisplay.includes('bouncingBack') && (
                        <div className="form__form-group">
                          <span className="form__form-group-label">
                            {translate('bouncingBack')}
                          </span>
                          <div className="form__form-group-field">
                            <input
                              value={state?.bouncingBack}
                              onChange={(e) => handleInputChange(e, setState)}
                              name="bouncingBack"
                              component="input"
                              type="number"
                              placeholder=""
                              disabled={actionId == ACTIONS_IDS.VIEW}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}

                {addons !== null &&
                  addons?.length > 0 &&
                  fieldsToDisplay.includes('addon') && (
                    <>
                      <h4 className="text-justify row">
                        {translate('addons')}
                      </h4>
                      <div className="card mt-4 mb-4 bg-light p-4">
                        <div className="form__form-group">
                          <div className="form__form-group-field">
                            <Select
                              isMulti
                              name="addons"
                              menuPortalTarget={document.querySelector('body')}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={addons}
                              onChange={(e) => selectAddons(e, setState)}
                              value={state?.addonIds}
                              isDisabled={actionId == ACTIONS_IDS.VIEW}
                            />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
              </>
            )}

            {actionId == ACTIONS_IDS.EDIT && (
              <>
                <h5
                  className="bold-text mb-4"
                  style={{
                    marginTop: '50px',
                  }}
                >
                  {translate('propertyMainImage')}
                </h5>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  {state?.mainImage?.length > 0 ? (
                    <>
                      <img
                        src={`${Config.UPLOADS_URL}uploads/${state.mainImage}`}
                        alt=""
                        style={{ width: '250px', height: '200px' }}
                      />
                      <button
                        onClick={() => handleRemoveMainImage()}
                        style={{
                          position: 'relative',
                          bottom: '10px',
                          left: '10px',
                          backgroundColor: 'red',
                          color: 'white',
                          border: 'none',
                          borderRadius: '50%',
                          width: '20px',
                          height: '20px',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        &times;
                      </button>
                    </>
                  ) : (
                    `${translate('noImages')}`
                  )}
                </div>
                <h5
                  className="bold-text mb-4"
                  style={{
                    marginTop: '50px',
                  }}
                >
                  {translate('estateImages')}
                </h5>
                <div
                  style={{
                    width: '100%',
                    display: 'grid',
                    gridTemplateColumns: 'repeat(3, 1fr)',
                    gridGap: '20px',
                    alignItems: 'center',
                  }}
                >
                  {state?.images?.length > 0
                    ? state?.images?.map((image, index) => (
                        <div
                          style={{
                            position: 'relative',
                            width: '250px',
                            height: '200px',
                          }}
                          key={index}
                        >
                          <img
                            src={`${Config.UPLOADS_URL}uploads/${image}`}
                            alt=""
                            style={{
                              width: '250px',
                              height: '200px',
                            }}
                          />
                          <button
                            onClick={() => handleRemoveImage(index)}
                            style={{
                              position: 'absolute',
                              top: '-10px',
                              left: '-10px',
                              backgroundColor: 'red',
                              color: 'white',
                              border: 'none',
                              borderRadius: '50%',
                              width: '20px',
                              height: '20px',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            &times;
                          </button>
                        </div>
                      ))
                    : `${translate('noImages')}`}
                </div>
              </>
            )}

            {actionId == ACTIONS_IDS.VIEW && !isRequest ? (
              <>
                <h5 className="bold-text mb-4">{translate('estateImages')}</h5>

                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: '#f7f7f7',
                    borderRadius: '15px',
                  }}
                >
                  {state?.images?.length > 0 ? (
                    <ImageCarousel images={state?.images} />
                  ) : (
                    `${translate('noImages')}`
                  )}
                </div>

                <h4
                  className="text-justify row bold-text"
                  style={{ marginTop: '50px' }}
                >
                  {translate('estateVideos')}
                </h4>
                <div
                  style={{
                    padding: '20px',
                    width: '100%',
                    backgroundColor: '#f7f7f7',
                    borderRadius: '15px',
                  }}
                >
                  {state?.youtubeVideos?.length > 0 ? (
                    <VideoPlayer videos={state.youtubeVideos} />
                  ) : (
                    translate('noVideo')
                  )}
                </div>
              </>
            ) : isRequest ? null : (
              <div className="flex flex-col p-4 bg-light rounded-lg">
                <div>
                  <h5 className="bold-text mb-4">
                    {translate('uploadPropertyMainImage')}
                  </h5>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <ImageUploader
                        onImageUpload={handleUploadedMainImage}
                        singleUpload={true}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="bold-text mb-4">
                    {translate('uploadPropertyImages')}
                  </h5>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <ImageUploader onImageUpload={handleUploadedImages} />
                    </div>
                  </div>
                </div>

                <div>
                  <h5 className="bold-text mb-4">
                    {translate('uploadPropertyVideo')}
                  </h5>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <StringArray
                        stringArray={state?.youtubeVideos}
                        setStringArray={(val) =>
                          setState({ ...state, youtubeVideos: val })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {actionId == ACTIONS_IDS.VIEW ? null : (
              <ButtonToolbar className="form__button-toolbar">
                <Button
                  color="primary"
                  onClick={
                    actionId == ACTIONS_IDS.ADD
                      ? addEstate
                      : actionId == ACTIONS_IDS.EDIT
                      ? editEstate
                      : activateEstate
                  }
                >
                  {actionId == ACTIONS_IDS.ADD
                    ? translate('submit')
                    : actionId == ACTIONS_IDS.EDIT
                    ? translate('submit')
                    : translate('activate')}
                </Button>
              </ButtonToolbar>
            )}
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form', // a unique identifier for this form
})(HorizontalForm);

import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import HorizontalForm from './components/HorizontalForm';
import { showResults } from '../../../utils/functions';
import { useLanguage } from '../../../i18n/LanguageContext';

const AddForm = () => {
  const { translate } = useLanguage();
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{translate('addAgent')}</h3>
        </Col>
      </Row>
      <Row>
        <HorizontalForm onSubmit={showResults} />
      </Row>
    </Container>
  );
};

AddForm.propTypes = {
  t: PropTypes.func,
};

export default AddForm;

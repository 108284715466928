import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import axios from 'axios';
import { useLanguage } from '../../../../i18n/LanguageContext';
import { showToast } from '../../../../utils/toast';
import { sessionExpire } from '../../../../redux/actions/authAction';
import { useDispatch } from 'react-redux';
import ResponsiveInput from '../../../../shared/components/ResponsiveInput';
import * as Config from '../../../../config/index';

const HorizontalForm = ({ handleSubmit }) => {
  const dispatch = useDispatch();
  const { translate } = useLanguage();
  const [formData, setFormData] = useState({
    numberOfFreePosts: 0,
    turnOnPayment: false,
    paidPostPrice: 0,
  });

  useEffect(() => {
    getItemHandler();
  }, []);

  const getItemHandler = async () => {
    axios
      .get(`${Config.BASE_URL}Common/settings`)
      .then((response) => {
        if (response.status === 200) {
          setFormData({
            numberOfFreePosts: response.data.data?.numberOfFreePosts,
            turnOnPayment: response.data.data?.turnOnPayment,
            paidPostPrice: response.data.data?.paidPostPrice,
          });
        } else {
          showToast(response.data.message || translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          showToast(error.response.data.message || String(error), 'error');
        }
      });
  };

  const _onEditItemHandler = async () => {
    axios
      .put(`${Config.BASE_URL}Common/updateSettings`, formData)
      .then(async (response) => {
        if (response.status === 200) {
          getItemHandler();
          showToast(translate('updatedSuccessfully'), 'success');
        } else {
          showToast(response.data.message || translate('error'), 'error');
        }
      })
      .catch((error) => {
        if (!error.response) {
          showToast(String(error), 'error');
        } else if (error.response.status === 401) {
          showToast(translate('sessionExpire'), 'error');
          dispatch(sessionExpire());
        } else {
          showToast(error.response.data.message || String(error), 'error');
        }
      });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    // Add validation if needed
    _onEditItemHandler();
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form onSubmit={handleFormSubmit}>
            <ResponsiveInput
              label={translate('turnOnPayment')}
              id="turnOnPayment"
              type="checkbox"
              value={formData?.turnOnPayment || false}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  turnOnPayment: e.target.checked,
                })
              }
            />

            <ResponsiveInput
              label={translate('numberOfFreePosts')}
              id="numberOfFreePosts"
              type="number"
              value={formData?.numberOfFreePosts || ''}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  numberOfFreePosts: e.target.value,
                })
              }
              min="0"
            />

            <ResponsiveInput
              label={translate('paidPostPrice')}
              id="paidPostPrice"
              type="number"
              value={formData?.paidPostPrice || ''}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  paidPostPrice: e.target.value,
                })
              }
              min="0"
            />

            <Button color="primary" type="submit" style={{ marginTop: 100 }}>
              {translate('submit')}
            </Button>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default HorizontalForm;

import axios from 'axios';
import { BASE_URL } from './config';

export const getDashboardData = async (id) => {
  const agentIdParam = id ? `?id=${id}` : '';

  const url = `${BASE_URL}Admin-Dashboard/getDashBoardData${agentIdParam}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

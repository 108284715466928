import React, { useState } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useLanguage } from '../../../../i18n/LanguageContext';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import { AdminAPI } from '../../../../apis';
import { showToast } from '../../../../utils/toast';

const userTypesEn = [
  { label: 'Personal', value: 1 },
  { label: 'Company', value: 2 },
];

const HorizontalForm = ({ handleSubmit, reset }) => {
  const navigate = useNavigate();
  const { translate } = useLanguage();

  const [formData, setFormData] = useState({
    nameEn: '',
    nameAr: '',
    password: '',
    email: '',
    isSuperAdmin: false,
    userType: userTypesEn[0].value,
  });

  const _onAddUserHandeller = async () => {
    const dataToSend = {
      nameEn: formData.nameEn,
      nameAr: formData.nameAr,
      password: formData.password,
      email: formData.email,
      userType: formData.userType,
    };

    const response = await AdminAPI.addAdmin(dataToSend);
    if (response.status === 200) {
      setFormData({
        nameEn: response.data.data?.nameEn,
        nameAr: response.data.data?.nameAr,
        password: response.data.data?.password,
        email: response.data.data?.email,
        userType: userTypesEn[0].value,
      });
      navigate(`/admins/list`);
      showToast(translate('addedSuccessfully'), 'success');
    } else {
      showToast(response.message || translate('error'), 'error');
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form className="form form--horizontal" onSubmit={handleSubmit}>
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('fullNameAr')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="nameAr"
                  component="input"
                  type="text"
                  placeholder={translate('fullNameAr')}
                  value={formData.nameAr}
                  onChange={(e) =>
                    setFormData({ ...formData, nameAr: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('fullNameEn')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="nameEn"
                  component="input"
                  type="text"
                  placeholder={translate('fullNameEn')}
                  value={formData.nameEn}
                  onChange={(e) =>
                    setFormData({ ...formData, nameEn: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('email')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component="input"
                  type="text"
                  placeholder={translate('email')}
                  value={formData.email}
                  onChange={(e) =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('password')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="password"
                  component="input"
                  type="text"
                  placeholder={translate('enterPassword')}
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('isSuperAdmin')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="radio2"
                  component={renderRadioButtonField}
                  label={translate('yes')}
                  radioValue={true}
                  defaultChecked
                  onChange={(e) =>
                    setFormData({ ...formData, isSuperAdmin: true })
                  }
                  value={formData.isSuperAdmin}
                />
                <Field
                  name="radio2"
                  component={renderRadioButtonField}
                  label={translate('no')}
                  radioValue={false}
                  onChange={(e) =>
                    setFormData({ ...formData, isSuperAdmin: false })
                  }
                  value={formData.isSuperAdmin}
                />
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={
                  !formData.nameEn ||
                  !formData.nameAr ||
                  !formData.password ||
                  !formData.email ||
                  !formData.userType
                }
                onClick={_onAddUserHandeller}
              >
                {translate('submit')}
              </Button>
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);

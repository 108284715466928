import React from 'react';
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import { FaBars } from 'react-icons/fa';
import logo from '../../shared/img/logo/estate-logo.png'; // Path to your logo image
import { useLanguage } from '../../i18n/LanguageContext';
import { logout } from '../../redux/actions/authAction';
import { useDispatch, useSelector } from 'react-redux';
import './TopBar.css';

const TopBar = ({ toggleSidebar }) => {
  const { isRTL, language, toggleLanguage } = useLanguage();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const dispatch = useDispatch();
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const isAgent = useSelector((state) => state.auth.isAgent);

  return (
    <Navbar
      color="light"
      light
      expand="md"
      className={`topbar ${isRTL ? 'rtl' : 'ltr'}`}
    >
      <div className="d-flex align-items-center topbar-left">
        {!isAgent && (
          <Button
            color="light"
            className="sidebar-toggle"
            onClick={toggleSidebar}
          >
            <FaBars />
          </Button>
        )}
        <NavbarBrand href="/" className="mb-0 h1 navbar-brand-logo">
          <img src={logo} alt="App Logo" height="30" />
        </NavbarBrand>
      </div>
      <Nav className="ml-auto" navbar>
        {/* <NavItem>
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle caret>{language.toUpperCase()}</DropdownToggle>
            <DropdownMenu right={isRTL}>
              <DropdownItem onClick={() => toggleLanguage('en')}>
                EN
              </DropdownItem>
              <DropdownItem onClick={() => toggleLanguage('ar')}>
                AR
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </NavItem> */}
        <NavItem>
          <NavLink
            href="#"
            onClick={() => toggleLanguage(language === 'en' ? 'ar' : 'en')}
          >
            {isRTL ? 'En' : 'Ar'}
          </NavLink>
        </NavItem>
        <div style={{ width: 20 }} />
        <NavItem>
          <NavLink href="#" onClick={() => dispatch(logout())}>
            Sign Out
          </NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default TopBar;

import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { Field, reduxForm } from 'redux-form';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { useLanguage } from '../../../../i18n/LanguageContext';
import { showToast } from '../../../../utils/toast';
import renderRadioButtonField from '../../../../shared/components/form/RadioButton';
import { AgentAPI } from '../../../../apis';

const HorizontalForm = ({ handleSubmit, reset, t }) => {
  const { translate } = useLanguage();
  const navigate = useNavigate();

  const [newEmail, setNewEmail] = useState('');
  const [formData, setFormData] = useState({
    adminId: '',
    nameEn: '',
    nameAr: '',
    email: '',
    password: '',
    isAgent: true,
  });

  useEffect(() => {
    getUserHandler();
  }, []);

  const getUserHandler = async () => {
    const url_string = document.URL;
    const url = new URL(url_string);
    const id = url.searchParams.get('id');

    const response = await AgentAPI.getAgentById(id);
    if (response.status === 200) {
      const { _id, nameEn, nameAr, email, password } = response.data.data;
      setFormData({
        adminId: _id,
        nameEn,
        nameAr,
        email,
        password,
      });
    } else {
      return response.data.message
        ? showToast(response.data.message, 'error')
        : showToast(translate('error'), 'error');
    }
  };

  const _onEditUserHandeller = async () => {
    let dataToSend = {
      adminId: formData.adminId,
    };
    if (formData.nameEn) {
      dataToSend.nameEn = formData.nameEn;
    }
    if (formData.nameAr) {
      dataToSend.nameAr = formData.nameAr;
    }
    if (formData.email !== newEmail) {
      dataToSend.email = newEmail;
    } else {
      dataToSend.email = null;
    }
    if (formData.password) {
      dataToSend.password = formData.password;
    }

    const response = await AgentAPI.editAgent(dataToSend);
    if (response.status === 200) {
      getUserHandler();
      showToast(translate('updatedSuccessfully'), 'success');
      navigate('/agents/list');
    } else {
      return response.data.message
        ? showToast(response.data.message, 'error')
        : showToast(translate('error'), 'error');
    }
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <form
            className="form form--horizontal"
            onSubmit={handleSubmit(_onEditUserHandeller)}
          >
            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('fullNameAr')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.nameAr}
                  onChange={(e) =>
                    setFormData({ ...formData, nameAr: e.target.value })
                  }
                  name="nameAr"
                  component="input"
                  type="text"
                  placeholder={translate('fullNameAr')}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('fullNameEn')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.nameEn}
                  onChange={(e) =>
                    setFormData({ ...formData, nameEn: e.target.value })
                  }
                  name="nameEn"
                  component="input"
                  type="text"
                  placeholder={translate('fullNameEn')}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('email')}
              </span>
              <div className="form__form-group-field">
                <input
                  value={formData.email}
                  onChange={(e) => setNewEmail(e.target.value)}
                  name="email"
                  component="input"
                  type="text"
                  placeholder={translate('email')}
                />
              </div>
            </div>

            <div className="form__form-group">
              <span className="form__form-group-label">
                {translate('password')}
              </span>
              <div className="form__form-group-field">
                <Field
                  name="password"
                  component="input"
                  type="text"
                  placeholder={translate('enterPassword')}
                  value={formData.password}
                  onChange={(e) =>
                    setFormData({ ...formData, password: e.target.value })
                  }
                />
              </div>
            </div>

            <ButtonToolbar className="form__button-toolbar">
              <Button
                color="primary"
                disabled={
                  !formData.nameAr ||
                  !formData.nameEn ||
                  (!newEmail && !formData.email)
                }
                type="submit"
              >
                {translate('submit')}
              </Button>
              {/* <Button type='button' onClick={reset}>
                Cancel
              </Button> */}
            </ButtonToolbar>
          </form>
        </CardBody>
      </Card>
    </Col>
  );
};

HorizontalForm.propTypes = {
  t: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'horizontal_form',
})(HorizontalForm);

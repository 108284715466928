import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useLanguage } from '../../i18n/LanguageContext';
import Sidebar from '../Layout/SideBar';
import TopBar from '../Layout/TopBar';
import './ScreenWrapper.css';

const ScreenWrapper = () => {
  const { isRTL } = useLanguage();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
  const closeSidebar = () => setIsSidebarOpen(false);

  const contentStyle = {
    marginLeft: !isRTL && isSidebarOpen ? '250px' : '0',
    marginRight: isRTL && isSidebarOpen ? '250px' : '0',
    paddingTop: '56px',
    transition: 'margin 0.3s ease',
  };

  return (
    <div className={isRTL ? 'rtl' : 'ltr'}>
      <TopBar toggleSidebar={toggleSidebar} />
      <Sidebar isSidebarOpen={isSidebarOpen} closeSidebar={closeSidebar} />
      <div className="content" style={contentStyle}>
        <Outlet />
      </div>
    </div>
  );
};

export default ScreenWrapper;

import axios from 'axios';
import { BASE_URL } from './config';

export const addAgent = async (data) => {
  const url = `${BASE_URL}Admin-Agents/addAgent`;

  try {
    const response = await axios.post(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAgentById = async (id) => {
  const url = `${BASE_URL}Admin-Agents/${id}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const editAgent = async (data) => {
  const url = `${BASE_URL}Admin-Agents/editAgent`;

  try {
    const response = await axios.patch(url, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllAgents = async (searchField, page, perPage) => {
  const searchParam = searchField ? `&search=${searchField}` : '';
  const url = `${BASE_URL}Admin-Agents/listAgents?page=${page}&perPage=${perPage}${searchParam}`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const getAllAgentsWithoutPages = async () => {
  const url = `${BASE_URL}Admin-Agents/listAgents`;

  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const activateDeactivateAgent = async (id) => {
  const url = `${BASE_URL}Admin-Agents/activeInnactiveAgent/${id}`;

  try {
    const response = await axios.patch(url);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteAgent = async (id) => {
  const url = `${BASE_URL}Admin-Agents/deleteAgent/${id}`;

  try {
    const response = await axios.patch(url);
    return response;
  } catch (error) {
    throw error;
  }
};

import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useLanguage } from '../../../i18n/LanguageContext';
import { BASE_URL } from '../../../config';
import { useDispatch } from 'react-redux';
import {
  setLoggedUserData,
  setLookupsData,
} from '../../../redux/actions/authAction';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../../utils/toast';
import useErrorhandling from '../../../utils/error';
import { LookupsAPI } from '../../../apis';

const LogInForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleError = useErrorhandling();
  const { language, translate } = useLanguage();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const showPasswordHandler = (e) => {
    e.preventDefault();
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const onLoginHandler = async () => {
    axios.defaults.headers.common['Accept-Language'] = language;
    const body = {
      email: email,
      password: password,
    };
    axios
      .post(`${BASE_URL}User-Auth/login`, body)
      .then(async (response) => {
        if (response.status === 200) {
          await getLookups();
          console.log(response.data.data);
          dispatch(setLoggedUserData(response.data.data));
          localStorage.setItem(
            'loggedData',
            JSON.stringify(response.data.data)
          );

          axios.defaults.headers.common[
            'Authorization'
          ] = `Bearer ${response.data.data.token}`;
          showToast(translate('success'), 'success');
          navigate('/dashboard');
        } else {
          return response.data.message
            ? showToast(response.data.message, 'error')
            : showToast(translate('error'), 'error');
        }
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getLookups = async () => {
    try {
      const response = await LookupsAPI.getLookups();

      if (response.status === 200) {
        dispatch(setLookupsData(response.data.data));
      } else {
        return response.data.message
          ? showToast(response.data.message, 'error')
          : showToast(translate('error'), 'error');
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="form">
      <div className="form__form-group">
        <span className="form__form-group-label">{translate('email')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            name="name"
            component="input"
            type="text"
            placeholder={translate('enterEmail')}
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">{translate('password')}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            name="password"
            component="input"
            type={showPassword ? 'text' : 'password'}
            placeholder={translate('enterPass')}
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <button
            className={`form__form-group-button${
              showPassword ? ' active' : ''
            }`}
            onClick={(e) => showPasswordHandler(e)}
            type="button"
          >
            <EyeIcon />
          </button>
        </div>
      </div>
      <button
        className="btn btn-primary account__btn account__btn--small"
        onClick={onLoginHandler}
      >
        {translate('signIn')}
      </button>
    </div>
  );
};

LogInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
};

export default reduxForm({
  form: 'log_in_form',
})(LogInForm);
